export function getAPIUrl(method) {
    //DEV
    //Aplikacja:
    //polite-devsite.vetrex.eu
    //API:
    //polite-dev.vetrex.eu
    // return `https://polite-dev.vetrex.eu/${method}`;
    
    //TEST
    //Aplikacja:
    //polite-test.vetrex.eu
    //API:
    //polite-test.vetrex.eu
    // return `https://polite-test.vetrex.eu/${method}`;

    //PROD
    //Aplikacja:
    //polite.vetrex.eu
    //API:
    //polite-prod.vetrex.eu
    return `https://polite-prod.vetrex.eu/${method}`;
}
