<template>
  <div style="display: flex; padding-bottom: 1rem">
    <div class="col-md-12">
      <div style="display: flex; justify-content: space-between">
        <div style="display: flex; font-size: 16px; align-items: center">
          <div style="margin-right: 2rem">
            {{ $t("strVariantPositionNo") }}
            <span style="margin-left: 0.5rem">{{ basket.noPosition }}</span>
          </div>
          <div style="margin-right: 2rem; display: flex">
            {{ $t("strPositionQuantity") }}
            <span style="margin-left: 0.5rem">
              <input
                type="number"
                min="1"
                max="999"
                v-model="basket.quantity"
                style="
                  background: #f7f7f7;
                  border: none;
                  text-align: center;
                  width: 100%;
                "
              />
            </span>
          </div>
        </div>
        <div style="display: flex">
          <div
            style="
              font-size: 18px;
              font-weight: bold;
              margin-right: 3rem;
              margin-top: 0.5rem;
            "
          >
            {{ $t("strPositionTotalValue") }}
            <span
              style="margin-left: 0.5rem"
              v-if="
                JSON.stringify(basket) === basketOrg || Number(basket.price) > 0
              "
            >
              {{ basket.price }}
            </span>
            <VButton
              v-if="
                JSON.stringify(basket) !== basketOrg ||
                  Number(basket.price) === 0
              "
              class="btn-green-confi"
              @click="btnCalculateHandle"
              :strKey="isEdit ? 'strCalculateSave' : 'strCalculate'"
            />
          </div>
          <div>
            <VButton
              class="product-header-btn"
              @click="dispalyPriceDetails = true"
              strKey="strShowPriceDetails"
            />
          </div>
        </div>
      </div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          margin-top: 3rem;
          padding-bottom: 1rem;
          flex-flow: row;
          flex-wrap: wrap;
          gap: 1rem;
        "
      >
        <div style="display: flex; font-size: 16px">
          <div style="display: flex">
            <div
              style="margin-right: 1rem"
              v-if="isEdit === true && JSON.stringify(basket) === basketOrg"
            >
              <VButton
                style="font-size:14px"
                class="product-header-btn--outline"
                @click="goToOffer"
                strKey="strGoToOffer"
              />
            </div>
            <div
              style="margin-right: 1rem"
              v-if="isEdit === true && JSON.stringify(basket) !== basketOrg"
            >
              <VButton
                style="font-size:14px"
                class="product-header-btn--outline"
                @click="save(false)"
                strKey="strSave"
              />
            </div>
            <div
              style="margin-right: 1rem"
              v-if="isEdit === true && JSON.stringify(basket) !== basketOrg"
            >
              <VButton
                style="font-size:14px"
                class="product-header-btn--outline"
                @click="save(true)"
                strKey="strSaveGoToOffer"
              />
            </div>
            <div
              style="margin-right: 1rem"
              v-if="isEdit === true && JSON.stringify(basket) !== basketOrg"
            >
              <VButton
                style="font-size:14px"
                class="product-header-btn--outline"
                @click="rejectChanges()"
                strKey="strRejectChanges"
              />
            </div>
            <div style="margin-right: 1rem" v-if="isEdit === false">
              <VButton
                style="font-size:14px"
                class="product-header-btn--outline"
                @click="addToOffer(false)"
                strKey="strAddToOffer"
              />
            </div>
            <div style="margin-right: 1rem" v-if="isEdit === false">
              <VButton
                style="font-size:14px"
                class="product-header-btn--outline"
                @click="addToOffer(true)"
                strKey="strAddToOfferClose"
              />
            </div>
            <div style="margin-right: 1rem" v-if="isEdit === false">
              <VButton
                style="font-size:14px"
                class="product-header-btn--outline"
                @click="deletePosition"
                strKey="strDeletePosition"
              />
            </div>
          </div>
        </div>
        <div style="display: flex">
          <div
            style="
              background: #fafafa;
              padding-left: 2.5rem;
              padding-right: 2.5rem;
              display: flex;
              align-items: center;
            "
          >
            {{ basket.width }}x{{ basket.height }}
          </div>
          <div
            style="
              background: #e1e1e1;
              padding-left: 1.5rem;
              padding-right: 1.5rem;
              display: flex;
              align-items: center;
              cursor: pointer;
            "
            @click="showDialog()"
          >
            {{ $t("strEdit") }}
          </div>
        </div>
      </div>
    </div>
    <Dialog
      widgetVar="process_msg"
      id="process_msg"
      :modal="true"
      :visible.sync="displayDialog"
      :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
      :style="{ width: '50vw' }"
    >
      <div style="padding: 1rem">
        <div style="font-size: 20px; padding-bottom: 1.5rem">
          {{ $t("strPosts") }}
        </div>
        <div class="row">
          <div class="col-md-6">
            <label class="offer--input-label" for="dateOfCreation">{{
              $t("strWindowWidth")
            }}</label>
            <div class="row">
              <div class="col-xs-12">
                <input
                  class="vetrex-inpt"
                  type="number"
                  showButtons
                  style="width: 100%"
                  v-model="currentData.width"
                />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <label class="offer--input-label" for="dateOfCreation">{{
              $t("strWindowHeight")
            }}</label>
            <div class="row">
              <div class="col-xs-12">
                <input
                  class="vetrex-inpt"
                  type="number"
                  showButtons
                  style="width: 100%"
                  v-model="currentData.height"
                />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div
                class="col-md-6"
                v-if="
                  verticalPosts !== undefined &&
                    verticalPosts !== null &&
                    verticalPosts.length > 0
                "
              >
                <div
                  style="
                    margin-bottom: 1rem;
                    margin-top: 1.5rem;
                    font-size: 12px;
                  "
                >
                  {{ $t("strSizeY") }}
                </div>
                <div
                  style="display: flex; margin-bottom: 1rem"
                  v-for="(item, index) of verticalPosts"
                  v-bind:key="index"
                >
                  <input
                    class="vetrex-inpt"
                    type="number"
                    showButtons
                    style="width: 100%"
                    v-model="item.x"
                  />
                </div>
              </div>
              <div
                class="col-md-6"
                v-if="
                  horizontalPosts !== undefined &&
                    horizontalPosts !== null &&
                    horizontalPosts.length > 0
                "
              >
                <div
                  style="
                    margin-bottom: 1rem;
                    margin-top: 1.5rem;
                    font-size: 12px;
                  "
                >
                  {{ $t("strSizeX") }}
                </div>
                <div
                  style="display: flex; margin-bottom: 1rem"
                  v-for="(item, index) of horizontalPosts"
                  v-bind:key="index"
                >
                  <input
                    class="vetrex-inpt"
                    type="number"
                    showButtons
                    style="width: 100%"
                    v-model="item.y"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12" style="margin-top: 2rem">
            <VButton
              v-if="
                verticalPosts !== undefined &&
                  verticalPosts !== null &&
                  verticalPosts.length > 0
              "
              style="
                    border: 2px solid #000;
                    background: none;
                    font-size: 12px;
                    border-radius: 4px;
                  "
              @click="alignY()"
              strKey="strAlignY"
            />
            <VButton
              v-if="
                horizontalPosts !== undefined &&
                  horizontalPosts !== null &&
                  horizontalPosts.length > 0
              "
              style="
                  border: 2px solid #000;
                  background: none;
                  font-size: 12px;
                  border-radius: 4px;
                  margin-left: 1.5rem;
                "
              @click="alignX()"
              strKey="strAlignX"
            />
          </div>
          <div class="col-md-12">
            <div style="display: flex; justify-content: flex-end">
              <div style="margin-right: 1rem">
                <VButton
                  class="product-header-btn--outline"
                  @click="displayDialog = false"
                  strKey="strCancel"
                />
              </div>
              <div>
                <VButton
                  class="product-header-btn"
                  @click="applyChanges()"
                  strKey="strOK"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
    <Dialog
      :header="$t('strShowPriceDetails')"
      widgetVar="process_msg"
      id="process_msg"
      :visible.sync="dispalyPriceDetails"
      :style="{ width: '80%' }"
      :modal="true"
    >
      <product-price-details
        :closePriceDetails="closePriceDetails"
        :priceDetails="priceDetails"
      />
    </Dialog>
    <Dialog
      widgetVar="process_msg"
      id="process_msg"
      :modal="true"
      :visible.sync="displayDialogCalculate"
      :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    >
      <div
        style="
        display: flex;
        padding: 5rem;
        justify-content: center;
        font-size: 20px;"
      >
        {{ $t("strAskCalculate1") }}{{ $t("strAskCalculate2") }}
      </div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          padding: 0 2rem 1rem 2rem;"
      >
        <VButton
          class="btn-outline-red"
          @click="displayDialogCalculate = false"
          strKey="strNot"
        />
        <VButton
          class="btn-outline-red"
          @click="closeDialogCalculate()"
          strKey="strYes"
        />
      </div>
    </Dialog>
  </div>
</template>

<script>
import ProductPriceDetails from "./ProductPriceDetails.vue";
import VButton from "../global/VButton.vue";
import { productService } from "../../services/product.service.js";

export default {
  name: "ProductHeader",
  props: {
    idPosition: Number,
    basket: {},
    basketOrg: String,
    priceDetails: Array,
    isEdit: Boolean,
  },
  data() {
    return {
      name: "Nazwa pozycji 03",
      amount: 1,
      displayDialog: false,
      dispalyPriceDetails: false,
      active: 0,
      activeHeight: 0,
      currentData: {},
      verticalPosts: [],
      horizontalPosts: [],
      displayDialogCalculate: false,
    };
  },
  methods: {
    btnCalculateHandle() {
      if (this.isEdit) this.displayDialogCalculate = true;
      else this.$parent.calculate(false);
    },
    closeDialogCalculate() {
      this.displayDialogCalculate = false;
      this.$parent.calculate(false);
    },
    showDialog() {
      this.currentData = { ...this.basket };
      this.verticalPosts = [];
      this.horizontalPosts = [];
      this.basket.posts.forEach((i, ind) => {
        if (i.position === "vertical") {
          let v = { ...this.basket };
          v.x = Number(i.x);
          v.index = ind;
          this.verticalPosts.push(v);
        } else {
          let h = { ...this.basket };
          h.y = Number(i.y);
          this.horizontalPosts.push(h);
          h.index = ind;
        }
      });

      // this.horizontalPosts = { ...this.basket.posts.filter( i=> i.position === 'horizontal')};
      // this.horizontalPosts.forEach(i => i.y = Number(i.y));
      this.displayDialog = true;
    },
    applyChanges() {
      this.basket.height = this.currentData.height;
      this.basket.width = this.currentData.width;
      this.verticalPosts.forEach((i) => (this.basket.posts[i.index].x = i.x));
      this.horizontalPosts.forEach((i) => (this.basket.posts[i.index].y = i.y));
      this.displayDialog = false;
    },
    closePriceDetails() {
      this.dispalyPriceDetails = false;
    },
    alignY() {
      if (this.verticalPosts?.length > 0) {
        this.verticalPosts.forEach(
          (i, ind) =>
            (i.x = Math.round(
              (this.currentData.width / (this.verticalPosts.length + 1)) *
                (ind + 1)
            ))
        );
      }
    },
    alignX() {
      if (this.horizontalPosts?.length > 0) {
        this.horizontalPosts.forEach(
          (i, ind) =>
            (i.y = Math.round(
              (this.currentData.height / (this.horizontalPosts.length + 1)) *
                (this.horizontalPosts.length - ind)
            ))
        );
      }
    },
    goToOffer() {
      this.$router.push(`/edit-offer/${Number(this.$route.params.idOffer)}`);
    },
    goToConfigurator() {
      this.$router.push(
        `/configurator/${Number(this.$route.params.idVariant)},${Number(
          this.$route.params.idOffer
        )}`
      );
    },
    addToOffer(goToOffer) {
      const thisVue = this;
      if (
        JSON.stringify(thisVue.basket) !== thisVue.basketOrg ||
        Number(thisVue.basket.price) === 0
      ) {
        this.$toast.add({
          severity: "error",
          summary: this.$t("strWindowNotCalculated"),
          detail: this.$t("strCantAddWariant"),
        });
        return;
      }
      productService.addToOffer(Number(this.idPosition)).then((response) => {
        if (response.isSuccess === false) {
          this.$toast.add({
            severity: "error",
            summary: this.$t("strOffer"),
            detail: response.statusMessage,
          });
          return;
        }

        this.$toast.add({
          severity: "success",
          summary: this.$t("strOffer"),
          detail: this.$t("strProductAddedToOffer"),
          life: 3000,
        });
        if (goToOffer) {
          thisVue.goToOffer();
        } else {
          thisVue.$router.push(
            `/configurator/product/${Number(this.$route.params.idTechnology)},${
              this.idPosition
            },${Number(this.$route.params.idVariant)},${Number(
              this.$route.params.idOffer
            )},true`
          );
          //thisVue.$parent.isEdit = true;
        }
      });
    },
    deletePosition() {
      this.goToConfigurator();
    },
    save(goToOffer) {
      this.$parent.calculate(goToOffer);
    },
    rejectChanges() {
      this.$parent.loadPosition(this.idPosition);
    },
  },
  computed: {
    singleItem() {
      return {
        "padding-top": "1rem",
        "padding-bottom": "1rem",
        "flex-grow": 1,
        "flex-shrink": 1,
        "flex-basis": 0,
        cursor: "pointer",
        "text-align": "center",
      };
    },
    singleItemActive() {
      return {
        "padding-top": "1rem",
        "padding-bottom": "1rem",
        "flex-grow": 1,
        "flex-shrink": 1,
        "flex-basis": 0,
        background: "#68bab5",
        color: "#fff",
        cursor: "pointer",
        "text-align": "center",
      };
    },
  },
  components: { ProductPriceDetails, VButton },
};
</script>

<style scoped>
.product-header-btn {
  background: #68bab5;
  border: 2px solid #68bab5;
  color: #fff;
  border-radius: 4px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 16px;
}
.product-header-btn--outline {
  border: 2px solid #68bab5;
  border-radius: 4px;
  color: #68bab5;
  background: #fff;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 16px;
}
</style>
